<template>
	<div class="common-items-center">
		<el-time-select  style="width:160px;"
		  v-model="valueA"   
		  :picker-options="{ start: '08:30', step: '00:15',  end: '18:30' }"
		  placeholder="选择开始时间">
		</el-time-select>
		<div class="jiange">~</div>
		<el-time-select style="width:160px;"
		  v-model="valueB"   
		  :picker-options="{ start: '08:30', step: '00:15',  end: '18:30' }"
		  placeholder="选择开始时间">
		</el-time-select>
	</div>
</template>

<script>
	export default {
		data() {
			return{
				valueA:"",
				valueB:""
			}
		},

		props:{
			with:{
				type:Number,
				default:100
			},
			
		},
		watch:{
		   	valueA:{
				handler(newVal) {
					this.getTimeFun()
				}
			},
			valueB:{
				handler(newVal) {
					this.getTimeFun()
				}
			},
		},
		mounted() {
			
		},
		methods:{
			getTimeFun(){
				let parms = {
					startTime:this.valueA,
					endTime:this.valueB,
				}
				this.$emit("getTimeBtn",parms)
			},
			setvalFun(ev){
				// ev = {
					
				// }
				this.valueA = ev.start_time
				this.valueB = ev.end_time
			}
		}
	}
</script>

<style lang="less" scoped>
	.jiange{
		padding: 0 10px;
	}
</style>