const HTTPAPI = {
    API:'https://api.wangshikeji.com',    
}

const INTER = {
      UPLOADIMH:`${HTTPAPI.API}/uploaFile`,    //上传图片
      LOGINAPI:`${HTTPAPI.API}/login`,         //登录
      GETINFO:`${HTTPAPI.API}/getLoginUser`,   //个人信息
      JICHUINFO:`${HTTPAPI.API}/getBaseData`,  //基础数据
      MENLIST:`${HTTPAPI.API}/getMenus`,       //菜单列表
      ODERLIST:`${HTTPAPI.API}/getBookOrder`,  //订单列标
      LIUSHUI:`${HTTPAPI.API}/getPayList`,     //流水的列表
	  BENLIST:`${HTTPAPI.API}/getBookData`,               //获取基础数据
	  BOOKDATA:`${HTTPAPI.API}/getUserBookingData`,       //获取某一天的数据
	  USERKADLIST:`${HTTPAPI.API}/getCardListByMemberId`, //会员购买的会员卡
	  USERLIST:`${HTTPAPI.API}/getBaseMemberList`,        //输入查询
	  YUEYUESUBMIT:`${HTTPAPI.API}/bookArenaPlace`,       //提交预约
	  MOBLELIST:`${HTTPAPI.API}/getAreaCode`,             //获取手机号区域 
	  CICARDLIST:`${HTTPAPI.API}/getTimeCards`,           //次卡列表  
	  CICARDADD:`${HTTPAPI.API}/addTimeCard`,             //新增次卡 
	  MOUNTHLIST:`${HTTPAPI.API}/getMonthCards`,          //月卡列表
	  YUEKAADD:`${HTTPAPI.API}/addMonthCard`,             //新增月卡
	  WEEKLIST:`${HTTPAPI.API}/getWeekCards`,             //周卡列表
	  WEEKADD:`${HTTPAPI.API}/addWeekCard`,               //新增周卡
	  STORELIST:`${HTTPAPI.API}/getStoredCards`,          //储值卡列表
	  STOREADD:`${HTTPAPI.API}/addStoredCard`,            //新增储值卡
	  MENDIANLIST:`${HTTPAPI.API}/getStoreList`,          //门店列表 
	  ADDMENDIAN:`${HTTPAPI.API}/addStore`,               //新增门店 
	  
	  USERLISTB:`${HTTPAPI.API}/getMemberList`,           //会员卡列表
	  USERADDEIDT:`${HTTPAPI.API}/addMember`,             //会员新增
	  MAIKALIEST:`${HTTPAPI.API}/getMemberCardList`,      //会员卡的列表  
      BUYUSERCARD:`${HTTPAPI.API}/buyCard`,               //给会员买卡
	  CAHNAGGUAN:`${HTTPAPI.API}/getStoreArenas`,         //场馆列表 
	  AACHANGGUAN:`${HTTPAPI.API}/addArena`,              //新增场馆
	  AACHANGDILI:`${HTTPAPI.API}/getArenaPlaces`,        //场地列表  
	  EIDTCHANGDILI:`${HTTPAPI.API}/addUpdatePlace`,      //场地编辑及其新增
	  DELETECHANGDI:`${HTTPAPI.API}/deleteArenaPlace`,	   //删除场地
	  DELETECHANCIFLIST:`${HTTPAPI.API}/getPlaceSettings`,   //场地配置--列表
	  ADDDELETECHANCIFLIST:`${HTTPAPI.API}/addPlaceSetting`, //场地配置--列表
	  DELETECHANGDICIFG:`${HTTPAPI.API}/delPlaceSetting`,    //场地配置--删除
	  SETISTOP:`${HTTPAPI.API}/setIsTop`,                    //场地配置--置顶置地
	  ALLSET:`${HTTPAPI.API}/getGlobalSetting`,              //全局设置
	  UPDATECIFALLSET:`${HTTPAPI.API}/updateGlobalSetting`,  //更新全局配置
	  
	  
}


const RULES = {
    NONEMPTY:/^[\s\S]*.*[^\s][\s\S]*$/,                                                               //非空验证
    PHONE:/^1[3456789]\d{9}$/,                                                                        //手机号验证
    EMAIL:/^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/,                       //邮箱验证
    IDCARD:/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,    //身份证正则
    PASSWORD:/^.*(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[!@#$%^&*?])\w{6,}$/,                          //密码的正则
    CHINESE:/^[\u4E00-\u9FA5]+$/,                                                                     //只允许输入中文 
    ENGCHEN:/^[A-Za-z0-9]+$/,                                                                         //只允许输入英文数字
    ENGCHENHSH:/^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{6,12}$/,                                        //匹配6~10位由数字和26个英文字母混合而成的密码
    ENGCHENUMBER:/^[A-Za-z0-9]{6,10}$/,                                                               //匹配6~10位由数字或者26个英文
    ENGCHENUMBEB:/^[A-Za-z0-9]{6,12}$/,                                                               //匹配6~12位由数字或者26个英文
    YINNOTESHU:/^(?=.*\d)(?=.*[a-zA-Z])(?=.*[~!@#$%^&*])[\da-zA-Z~!@#$%^&*]{6,10}$/,                  //必须包含数字、英文字母、特殊符号且大于等于8位（特殊符号包括: ~!@#$%^&*）
    YINNOTE:/^(?=.*\d)(?=.*[a-zA-Z])[\da-zA-Z~!@#$%^&*]{6,18}$/,                                      //数字、英文字母、特殊符号可有可无，6-18位
    HTTPRULS:/^(http(s)?:\/\/)?([0-9a-z-]{1,}.)?[0-9a-z-]{2,}.([0-9a-z-]{2,}.)?[a-z]{2,}$/i,          //域名  https://www.itbulu.com 或者 http://itbulu.com
    HTTPRULB:/^(http(s)?:\/\/)?(www\.)?([0-9a-z-]{1,}.)?[0-9a-z-]{2,}.([0-9a-z-]{2,}.)?[a-z]{2,}$/i,  //域名  必须输入WWW
    HTTPRULC:/^([0-9a-z-]{1,}.)?[0-9a-z-]{2,}.([0-9a-z-]{2,}.)?[a-z]{2,}$/i,                          //域名  可以不用HTTPS/HTTP 
    POSITVIE:/^\d+$/,                                                                                 //大于等于0的正整数                                                              
}



export {
    HTTPAPI,
    INTER,
    RULES
}