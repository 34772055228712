
import router from './route.js'
import citYvalue from './city'
//跳转到指定的Url
export function toUrlFun(parms) {
    // let parms = {
    //     type:"", 重定向replace  转发push
    //     path:"/Mallyou-home",
    //     query:{}
    // }
    let url = window.location.href
    if (url.indexOf(parms.path) == -1) {
        router[parms.type]({
            path: "/" + parms.path,
            query: parms.query
        })
    }
}

//获取城市的相应的Code
export function getCitycodeFun(ev){
	let valText = ev.split(',')
    let codeArray = []
	if(valText[0]=="上海市" || valText[0]=="天津市" || valText[0]=="北京市" || valText[0]=="重庆市"){
		mdirectlyFun(valText,res=>{
			codeArray = res
		})
		
	}else{
		for(var i in citYvalue){
			if(citYvalue[i].label==valText[0]){
				codeArray.push(citYvalue[i].value)
				let cityList =  citYvalue[i].children
				for(var j in cityList){
					if(cityList[j].label==valText[1]){
						codeArray.push(cityList[j].value)
						let streeList = cityList[j].children 
						for(var h in streeList){
							if(streeList[h].label == valText[2]){
								codeArray.push(streeList[h].value)
							}
						}
					}
				}
			}
		}
	}
   return codeArray
}
//换区城市相关的数据
export function  mdirectlyFun(val,callback){
	if(val[0]=="上海市" || val[0]=="天津市" || val[0]=="北京市" || val[0]=="重庆市"){
		let list = []
		for(var i in citYvalue){
			if(citYvalue[i].label == val[0]){
				let listC = citYvalue[i].children
				 for(var j in listC){
					 let listZ = listC[j].children
					 for(var h in listZ){
						 list.push({p:citYvalue[i].label,pcode:citYvalue[i].value,c:listC[j].label,ccode:listC[j].value,z:listZ[h].label,zcode:listZ[h].value})
					 } 
				 }
			}
		}
		for(var n in list){
			if(list[n].z==val[1]){
				callback([list[n].pcode,list[n].ccode,list[n].zcode])
				return 
			}
		}
	}
}


