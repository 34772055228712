<template>
    <div class="modal-wrap">
        <transition :name="'sidelip-down-modal'" @after-leave="afterLeave" @after-enter="afterEnter">
          <div class="modal-block"  v-if="state > -99" :style="{ 'width': width + 'px' }">
              <div class="modal-header common-items-center common-space-between ">
                  <div class="theme">{{title}}</div>
                  <div @click="closeBtn">
                    <i class="el-icon-close"></i>
                  </div>
              </div>
              <div class="modal-body" >
                <slot></slot>
              </div>
              <div class="modal-footer" v-if="actions.length > 0">
                <el-button class="page-modal-btn" :loading="actionState[item.action]" @click.native="item.handle()"  v-for="(item, index) in actions" :key="index" :type="item.theme" :icon="item.icon" size="medium">
                    <span>{{item.text}}</span> 
                </el-button>
              </div>
          </div>
        </transition>
    </div>
</template>
<script>
export default {
  components: {

  },
  props: {

  },
  data() {
    return {
      state: -99,
      actions: [],
      title: '',
      width: 600,
      actionState: {},
      closeCallBack() { }
    }
  },
  computed: {

  },
  created: function () {

  },
  beforeMount: function () {

  },
  mounted: function () {
    this.state = -1;
  },
  beforeDestroy: function () {

  },
  destroyed: function () {
    document.body.removeChild(this.$el);
  },
  methods: {
    hide() {
      this.state = -99;
    },
    closeBtn() {
      this.hide();
      this.closeCallBack();
    },
    setTitle(title) {
      this.title = title
    },
    setCloseCallBack(cb = () => { }) {
      this.closeCallBack = cb;
    },
    setPageActions(actions) {
      this.actions = actions;
      for (let a of this.actions) {
        if (!!a.action) {
          this.actionState[a.action] = false;
        }
      }
    },
    setSize(width) {
      this.width = width;
    },
    afterLeave() {
      this.$destroy();
    },
    afterEnter() {
      this.state = 0;
    },
    setActionsState(action, state) {
      this.actionState[action] = state;
      this.$forceUpdate();
    },
  },
  watch: {

  },
  directives: {

  }
}
</script>
<style lang="scss" scoped>
.sidelip-down-modal-enter-active {
  transform: translate3d(0, 0, 0);
}

.sidelip-down-modal-enter {
  transform: translate3d(0, calc(-100% - 5rem), 0);
}

.sidelip-down-modal-leave {
  transform: translate3d(0, 0, 0);
}

.sidelip-down-modal-leave-active {
  transform: translate3d(0, calc(-100% - 5rem), 0);
}
.modal-wrap {
    width:100vw;
    height:100vh;
    position: fixed;
    overflow: auto;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1011;
    -webkit-overflow-scrolling: touch;
    outline: 0;
    display:flex;
    justify-content:center;
    align-items:center;
    background-color: rgba(0,0,0,0.35);
  .modal-block {
        border-radius:8px;
        background-color:white;
        overflow:hidden;
        margin-bottom:200px;
        transition: transform .8s cubic-bezier(.36, .66, .04, 1);
      .modal-header{
          height:56px;
          padding:0 16px;
          box-sizing:border-box;
          background-color: #fff;
          border-bottom:1px solid #ECEEF0;
          .theme{
            font-family: PingFangSC, PingFang SC;
            font-weight: 500;
            font-size:18px;
            color: #333333;
          }
          .el-icon-close{
            font-size:22px;
            color:#66686A;
            cursor:pointer;
          }
      }
      .modal-body{
          padding:24px 24px 0 24px;
          box-sizing:border-box;
          max-height:calc(100vh - 300px);
      }
      .modal-footer{
          padding:16px;
          box-sizing:border-box;
          display:flex;
          justify-content:center;
          align-items:center;
      }
  }
}
</style>
