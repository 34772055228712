const status_list = {
     book_statu:[  //预约状态
        {title:"全部",val:0,color:""},
        {title:"已预约",val:1,color:""},
        {title:"已取消",val:2,color:""}
     ],
     pay_statu:[  //支付状态
        {title:"全部",val:0,color:""},
        {title:"待支付",val:1,color:""},
        {title:"已支付",val:2,color:""},
        // {title:"支付失败",val:3,color:""},
     ],
     oder_statu:[  //订单状态
        {title:"全部",val:0,color:""},
        {title:"已预约",val:1,color:""},
        {title:"已取消",val:2,color:""},
        {title:"已取消",val:3,color:""},
        {title:"已退款",val:4,color:""},
     ],
     pay_method:[  //支付方式
        {title:"全部",val:0,color:""},
        {title:"微信",val:1,color:""},
        {title:"支付宝",val:2,color:""},
        {title:"收钱吧",val:3,color:""},
        {title:"抖音",val:4,color:""},
        {title:"美团点评",val:5,color:""},
        {title:"现金",val:6,color:""},
        {title:"卡支付",val:7,color:""},
        {title:"内部结转",val:8,color:""},
        {title:"银行转账",val:9,color:""},

     ],
	 cark_statu:[  //支付状态
	    {title:"全部",val:0,color:""},
	    {title:"上架",val:1,color:""},
	    {title:"下架",val:2,color:""},
	 ],
}

export {
    status_list
}