<!-- 公共头部标题 -->
<template>
    <div class="warpTitlenAV common-items-center" :style='{borderBottom:borderBot}'>
		<div v-if="isreturn" @click="returnBtn">
			<i class="el-icon-back"></i>
		</div>
		<span>{{text}}</span>
	</div>
</template>

<script>
export default {
    name: "",

    data() {
        return {
            text:""  
        }
    },
	props: {
		title:{
			type: String,
			default: ""
		},
	    returnUrl: {
	        type: String,
	        default: ""
	    },
		borderBot:{
			type: String,
			default: ""
		},
	    isIcon:{
	        type:Boolean,
	        default:false
	    },
		isreturn:{
			type:Boolean,
			default:false
		}
	},
	watch:{
		title:{
			handler(newVal) {
				if(!newVal){
					 this.text = localStorage.getItem("navaataitle")  
				}else{
					this.text = this.title
				}
			},
			deep:true,
			immediate: true
		}
		
	},
    created() {

    },
    mounted() {
	     
    },

    methods:{
        returnBtn() {
           window.history.go(-1)
        }
    }
}
</script>
<style lang="less" scoped>
  .warpTitlenAV{
      height:58px;
      line-height:58px;
      background-color:white;
      border-radius:10px 10px 0 0;
      box-sizing:border-box;
      font-family: AlibabaPuHuiTi_2_85_Bold;
      font-size: 20px;
      color: #333333;
      font-weight:700;
  }
  .el-icon-back{
	  font-size:26px;
	  position: relative;
	  top:2px;
	  margin-right:6px;
  }
</style>