<template>
    <div class="common-space-around pagination">
        <el-pagination prev-text="上一页" next-text="下一页" background layout="prev, pager, next" :total="1000">
        </el-pagination>
    </div>
</template>

<script>
export default {
    name: "",
    data() {
        return {

        }
    },
    created() {

    },
    mounted() {

    }
}
</script>
<style lang="less" scoped>
/* @import url(); 引入css类 */
.pagination {
    padding: 50px 0 60px 0;
}
</style>