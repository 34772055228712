<template>
    <div class="warp-page">
      <el-pagination
        background
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="pageNo*1"
        :page-sizes="[10, 20, 30, 50, 100]"
        :page-size="size*1"
        layout="total, prev, pager, next "
        :total="total*1"
        >
      </el-pagination> 
    </div>
</template>
<script>
  export default{
    data:()=>({
     
    }),
    
    props:{
        pageNo:{
          type: Number,
          default: 1,  
        },
        total:{
          type: Number,
          default: 10,  
        },
        size:{
           type: Number,
           default: 10, 
        }
    },
    methods: {

      handleSizeChange(val) {
          let parms = {
              pageNo:1,
              pageSize:val 
          }
          this.$emit('getPage',parms)
      },
      handleCurrentChange(val) {
        let parms = {
            pageNo:val,
            pageSize:this.size 
        }
        this.$emit('getPage',parms)
      },
    },
  }
</script>
<style lang="scss" scoped>
   .warp-page{
      padding-top:10px;
      display: flex;
      justify-content:flex-end;
      user-select: none;
   }
</style>