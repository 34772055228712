<template>
  <div class="common-items-center" :style='{marginRight:right*1+"px"}'>
      <div class="title" v-if="showBlock">{{title}}</div>
      <el-select v-model="val" :size="size" :placeholder="placeholder" :disabled="disabled"  @change="selectVal" :style='{width:width*1+"px",marginLeft:left+"px"}'>
        <el-option
          v-for="item in options"
          :key="item.val"
          :label="item.title"
          :value="item.val">
        </el-option>
      </el-select>
  </div>
  </template>
  <script>
    export default {
      data() {
        return {
          val:"",
        }
      },
      props:{
        showBlock:{
          type: Boolean,
          default:false,
        },
        size:{
          type: String,
          default:"medium",
        },
        keyVal:{
          type: String,
          default: "key",
        },
        value:{
          type: String,
          default: "",
        }, 
        title: {
         type: String,
         default: "",
        },
        width:{
          type: String,
          default: "200",
        },
        left:{
          type: String,
          default: "8",
        },
        right:{
          type: String,
          default: "15",
        },
        disabled:{
          type: Boolean,
          default:false,
        },
        options:{
          type: Array,
          default:()=>([
            { val:0, title: '禁用'}, 
            { val:1, title: '启用'}
          ])
        },
        paddButtom:{
         type: String,
         default: "10",
       },
        placeholder:{
          type: String,
          default: "请选择",
        }
      },
      methods:{
        //选取的值
        selectVal(ev){
           let list = this.options
		   
           for(var i in list){
              if(list[i].val == ev){
                 list[i].key = this.keyVal
				 // console.log(list[i])
                 this.$emit('getVal',list[i])
                 break 
              }
           }
        },

        setVal(e){
           this.val = e 
        }
      }

    }
  </script>
  <style scoped lang="scss">
      .title{
        font-size: 14px;
        color: #606266;
        text-align: right;
        position: relative;
        bottom:1px;
      }
  </style>