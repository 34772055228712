import Vue from 'vue'
import App from './App.vue'
import 'element-ui/lib/theme-chalk/index.css';
import ElementUI from 'element-ui';
import router from './utils/route'
import store  from './utils/store'
import {toUrlFun} from './utils/utils'
import httpService from './utils/httpRequest'

// 引入阿里字体图标
import './assets/fontIcon/iconfont.css'

import PageModal from './components/Block-model';
import PageComponent from './components/page-Block'
import NavTitle from './components/module-navTitle'
import BlockSelectA from "@/components/Block-selectTree/index.vue"
import BlockTable from "@/components/module-table/index.vue"
import BlockPage from "@/components/module-public/block-paging.vue"
import BlockMonth from "@/components/Block-month/index.vue"
import BlockSelectB from "@/components/Block-select/index.vue"
import BlockPrice from "@/components/Block-price/index.vue"
import BlockPop from "@/components/module-pop/index.vue"
import BlockUpload from "@/components/module-upload/index.vue"
import BlockTime from "@/components/module-time/index.vue"

Vue.component('PageComponent',PageComponent)
Vue.component('NavTitle',NavTitle)
Vue.component('BlockSelectA',BlockSelectA)
Vue.component('BlockSelectB',BlockSelectB)
Vue.component('BlockTable',BlockTable)
Vue.component('BlockPage',BlockPage)
Vue.component('BlockMonth',BlockMonth)
Vue.component('BlockPrice',BlockPrice)
Vue.component('BlockPop',BlockPop)
Vue.component('BlockUpload',BlockUpload)
Vue.component('BlockTime',BlockTime)

Vue.config.productionTip = false
Vue.use(ElementUI);
Vue.prototype.$toUrlFun = toUrlFun
Vue.prototype.$httpRequest = httpService
Vue.prototype.$PageModal = PageModal;


const win = new Vue({
  render: h => h(App),
  router,
  store
}).$mount('#app')

window.win = win;
// 放入地图坐标
window.mapKey = {
  AutoNaviKey: '3b592008854f47146c257ecc6e5b0dc5',     // 高德key
  TencentKey:'ZSOBZ-5LCK3-OOT3Z-3BBHJ-EKHKQ-ZXBQW',       // 腾讯key
  BaiduKey:'bKGEmLs4E9SGEGQ4NIxcfLESpcrLK5Bg',         // 百度key
}