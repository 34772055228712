<template>
	<!--显示价格-->
	<span>
		<span v-if="dection" :style='{color:color,marginRight:right+"px"}'>
			<span :style='{fontSize:sizeA+"px",fontWeight:fontWeightA}'>￥</span>
			<span :style='{fontSize:sizeB+"px",fontWeight:fontWeightB}'>{{integerPart}}</span>
			<span :style='{fontSize:sizeC+"px",fontWeight:fontWeightC}'>.{{decimalPart}}</span>
		</span>
		<span v-else class="cost" :style='{fontSize:sizeA+"px",color:color,marginRight:right+"px"}'>￥{{integerPart}}.{{decimalPart}}</span>
	</span>
</template>
<script>
	export default{
		data(){
		    return{
			  
			} 
		},
		props:{
		   val:{
			   type:Number,
			   default:0.00
		   },
		   color:{
			   type:String,
			   default:"#ADADAD"
		   },
		   right:{
			  type:Number,
			  default:2 
		   },
		   dection:{
			   type:Boolean,
			   default:true
		   },
		   sizeA:{
			   type:Number,
			   default:20
		   },
		   sizeB:{
			   type:Number,
			   default:20
		   },
		   sizeC:{
			   type:Number,
			   default:20
		   },
		   fontWeightA:{
			   type:Number,
			   default:500
		   },
		   fontWeightB:{
			   type:Number,
			   default:500
		   },
		   fontWeightC:{
			   type:Number,
			   default:500
		   }
		},
		computed:{
			integerPart(){
				let value = !!this.val?(this.val).toFixed(2):"0.00"
				return value.split('.')[0]
			},
			decimalPart(){
				let value = !!this.val?(this.val).toFixed(2):"0.00"
				return value.split('.')[1]
			}
		}
	}
</script>
<style scoped lang="scss">
	.cost{
		// font-family: HarmonyOS_Sans_SC_Medium;
		// text-align: left;
		// font-style: normal;
		// line-height: 38rpx;
		text-decoration-line: line-through;
	}
</style>